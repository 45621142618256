import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { environment } from '@environments';
import Clarity from '@microsoft/clarity';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/config/app.config';

if (environment.production) {
  enableProdMode();
}

async function bootstrap() {
  // if (window.screen.width <= 768) {
  //   const xhr = new XMLHttpRequest();
  //   xhr.open('GET', '/assets/mobile-iframe.html');
  //   xhr.onload = function () {
  //     const pattern = /<body[^>]*>((.|[\n\r])*)<\/body>/im;
  //     const html = pattern.exec(xhr.responseText);
  //     if (html) {
  //       window.document.body.innerHTML = html[1];
  //     }
  //   };
  //   xhr.send();
  //   return;
  // if (window.location.host == 'kyonsvn.web.app') window.location = 'https://kyonsvn.web.app/m';
  // if (window.location.host == 'student.kyons.vn') window.location = 'https://kyonsvn.web.app/student';
  // }

  await bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
  const searchParams = new URLSearchParams(window.location.search);
  const outline = searchParams.get('outline');

  if (outline === 'true') {
    document.body.classList.add('outline');
  }
  else {
    document.body.classList.remove('outline');

  }
  const projectId = environment.clarityAppId;

  Clarity.init(projectId);
}

if (document.readyState === 'complete') {
  // bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}
